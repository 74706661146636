import HTTP from "./../../utils/Http";
import BackendHttp from "./../../utils/BackendHttp";

// Actions
const AUTH_CHECK = "AUTH_CHECK";
const AUTH_LOGIN = "AUTH_LOGIN";
const AUTH_LOGOUT = "AUTH_LOGOUT";
const AUTH_MFA = "AUTH_MFA";
const AUTH_USER = "AUTH_USER";
const AUTH_REFRESH_TOKEN = "AUTH_REFRESH_TOKEN";
const AUTH_RESET_PASSWORD = "AUTH_RESET_PASSWORD";
const AUTH_ADMINREFRESH_TOKEN = "AUTH_ADMINREFRESH_TOKEN";
const AUTH_ADMINLOGIN = "AUTH_ADMINLOGIN";
const AUTH_ADMINCHECK = "AUTH_ADMINCHECK";
const AUTH_ADMINMFA = "AUTH_ADMINMFA";
const AUTH_ADMINLOGOUT = "AUTH_ADMINLOGOUT";
const AUTH_ADMINUSER = "AUTH_ADMINUSER";
const UPDATE_SELECTED_GROUP = "UPDATE_SELECTED_GROUP";

// Action Creators
export function adminauthCheck() {
  return { type: AUTH_ADMINCHECK };
}

export function adminauthLogin(payload) {
  return { type: AUTH_ADMINLOGIN, payload };
}

export function adminauthMFA() {
  return {
    type: AUTH_ADMINMFA,
  };
}

export function adminauthLogout() {
  return {
    type: AUTH_ADMINLOGOUT,
  };
}

export function adminauthUser(payload) {
  return {
    type: AUTH_ADMINUSER,
    payload,
  };
}

export function authCheck() {
  return { type: AUTH_CHECK };
}

export function authLogin(payload) {
  return { type: AUTH_LOGIN, payload };
}

export function authLogout() {
  return {
    type: AUTH_LOGOUT,
  };
}

export function authMFA() {
  return {
    type: AUTH_MFA,
  };
}

export function authUser(payload) {
  return {
    type: AUTH_USER,
    payload,
  };
}

export function authRefreshToken(payload) {
  return {
    type: AUTH_REFRESH_TOKEN,
    payload,
  };
}

export function authResetPassword() {
  return {
    type: AUTH_RESET_PASSWORD,
  };
}

export function updateSelectedGroup(payload) {
  return {
    type: UPDATE_SELECTED_GROUP,
    payload,
  };
}

const initialState = {
  isAuthenticated: false,
  isMFAAuthenticated: false,
  isAdminAuthenticated: false,
  isMFAAdminAuthenticated: false,
  adminUser: {},
  user: {
    enterpriseId: { address: {} },
    roleId: {},
  },
  selectedGroup: "",
  selectedGroupVal: "",
  buttonStyle: {
    color: "#FFF",
    borderColor: "#038389",
    backgroundColor: "#038389",
  },
  buttonStyleHover: {
    opacity: "0.8",
    color: "#FFF",
    borderColor: "#038389",
    backgroundColor: "#038389",
  },
};

// Reducer
export default function reducer(
  state = initialState,
  { type, payload = null }
) {
  switch (type) {
    case AUTH_REFRESH_TOKEN:
    case AUTH_LOGIN:
      return login(state, payload);
    case AUTH_CHECK:
      return checkAuth(state);
    case AUTH_LOGOUT:
      return logout(state);
    case AUTH_MFA:
      return mfaAuth(state);
    case AUTH_RESET_PASSWORD:
      return resetPassword(state);
    case AUTH_USER:
      return setauthuser(state, payload);
    case AUTH_ADMINREFRESH_TOKEN:
    case AUTH_ADMINLOGIN:
      return adminlogin(state, payload);
    case AUTH_ADMINCHECK:
      return checkAdminAuth(state);
    case AUTH_ADMINMFA:
      return mfaAdminAuth(state);
    case AUTH_ADMINLOGOUT:
      return adminlogout(state);
    case AUTH_ADMINUSER:
      return setadminauthuser(state, payload);
    case UPDATE_SELECTED_GROUP:
      return updateSelectedGroupReducer(state, payload);
    default:
      return state;
  }
}

function adminlogin(state, payload) {
  sessionStorage.setItem("admin_access_token", payload);
  BackendHttp.defaults.headers.common["x-access-token"] = `${payload}`;

  return {
    ...state,
    isAdminAuthenticated: true,
  };
}

function checkAdminAuth(state) {
  state = Object.assign({}, state, {
    isAdminAuthenticated: !!sessionStorage.getItem("admin_access_token"),
  });

  if (state.isAdminAuthenticated) {
    BackendHttp.defaults.headers.common[
      "x-access-token"
    ] = `${sessionStorage.getItem("admin_access_token")}`;
  }

  return state;
}

function mfaAdminAuth(state) {
  return {
    ...state,
    isMFAAdminAuthenticated: true,
  };
}

function adminlogout(state) {
  sessionStorage.removeItem("admin_access_token");

  return {
    ...state,
    isAdminAuthenticated: false,
    adminUser: {},
  };
}

function setadminauthuser(state, adminUser) {
  return {
    ...state,
    adminUser: { ...adminUser },
  };
}

function updateSelectedGroupReducer(state, payload) {
  return {
    ...state,
    selectedGroup: payload._id,
    selectedGroupVal: payload.name,
  };
}

function login(state, payload) {
  sessionStorage.setItem("access_token", payload);
  HTTP.defaults.headers.common["xf-access-token"] = `${payload}`;

  return {
    ...state,
    isAuthenticated: true,
  };
}

function checkAuth(state) {
  state = Object.assign({}, state, {
    isAuthenticated: !!sessionStorage.getItem("access_token"),
  });

  if (state.isAuthenticated) {
    HTTP.defaults.headers.common["xf-access-token"] = `${sessionStorage.getItem(
      "access_token"
    )}`;
  }

  return state;
}

function logout(state) {
  sessionStorage.removeItem("access_token");
  sessionStorage.removeItem("loginAsEnterprise");

  return {
    ...state,
    isAuthenticated: false,
    isMFAAuthenticated: false,
    user: {
      enterpriseId: {
        address: {},
      },
      roleId: {},
    },
  };
}

function mfaAuth(state) {
  return {
    ...state,
    isMFAAuthenticated: true,
  };
}

function setauthuser(state, data) {
  state = Object.assign({}, state, {
    buttonStyle: {
      color: data.enterpriseId.themeSettings?.buttonColor,
      borderColor: data.enterpriseId.themeSettings?.buttonBackColor,
      backgroundColor: data.enterpriseId.themeSettings?.buttonBackColor,
    },
    buttonStyleHover: {
      color: data.enterpriseId.themeSettings?.buttonHoverColor,
      borderColor: data.enterpriseId.themeSettings?.buttonBackHoverColor,
      backgroundColor: data.enterpriseId.themeSettings?.buttonBackHoverColor,
    },
    user: data,
  });
  return state;
}

function resetPassword(state) {
  return {
    ...state,
    resetPassword: true,
  };
}
