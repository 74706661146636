import React, { Component } from "react";
import ReactPlayer, { FilePlayer } from "react-player";
import { Link } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { doAction } from "./../enterprise/contentLibrary/services";
import { markTaskAsCompletedReq } from "./../enterprise/taskLibrary/services";
import { authLogout } from "./../../store/reducers/auth";
import MSG, { notifyError, notifySuccess } from "./../../utils/Helper";

import {
  togglePlayerModal,
  toggleJournalingQuestionModal,
  setJournalingQuestionData,
} from "./../../store/reducers/generalActions";

const Duration = ({ className, seconds }) => {
  return (
    <time dateTime={`P${Math.round(seconds)}S`} className={className}>
      {format(seconds)}
    </time>
  );
};

function format(seconds) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
}

function pad(string) {
  return ("0" + string).slice(-2);
}

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pip: false,
      playing: false,
      speedlist: false,
      controls: false,
      light: false,
      volume: 0.8,
      muted: false,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,
      videoViewedApiCall: false,
    };
  }

  load = (url) => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      muted: false,
      playbackRate: 1.0,
      pip: false,
    });
  };

  handleToggleSpeedList = () => {
    this.setState({ speedlist: !this.state.speedlist });
  };

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  handleStop = () => {
    this.setState({ url: null, playing: false });
  };

  handleToggleControls = () => {
    const url = this.state.url;
    this.setState(
      {
        controls: !this.state.controls,
        url: null,
      },
      () => this.load(url)
    );
  };

  handleToggleLight = () => {
    this.setState({ light: !this.state.light });
  };

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop });
  };

  handleVolumeChange = (e) => {
    this.setState({ volume: parseFloat(e.target.value) });
  };

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  handleSetPlaybackRate = (val) => {
    this.setState({ playbackRate: parseFloat(val) });
  };

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip });
  };

  handlePlay = () => {
    // console.log("onPlay");
    this.setState({ playing: true });
  };

  handleEnablePIP = () => {
    // console.log("onEnablePIP");
    this.setState({ pip: true });
  };

  handleDisablePIP = () => {
    // console.log("onDisablePIP");
    this.setState({ pip: false });
  };

  handlePause = () => {
    // console.log("onPause");
    this.setState({ playing: false });
  };

  handleSeekMouseDown = (e) => {
    this.setState({ seeking: true });
  };

  handleSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) });
  };

  handleSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
  };

  handleProgress = (state) => {
    // console.log("onProgress", state);
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      if (
        this.props.isAuthenticated &&
        state.playedSeconds > 0 &&
        !this.state.videoViewedApiCall
      ) {
        const { duration, playedSeconds } = this.state;
        const playedPercentage = Math.ceil((playedSeconds / duration) * 100);

        if (playedPercentage > 80) {
          this.setState({
            videoViewedApiCall: true,
          });
          const { contentId } = this.props.videoData;
          // console.log("api called");
          if (!_.isEmpty(contentId)) {
            this.performAction(contentId, "viewed");
          }
          // here we have to check if video is of task
          // if yes then mark it as completed
          if (
            this.props.videoData.isTask &&
            !this.props.videoData.isTaskCompleted
          ) {
            this.markTaskAsCompleted();
          }
        }
      }
      this.setState(state);
    }
  };

  markTaskAsCompleted = async () => {
    try {
      let req = {
        userId: this.props.user._id,
        taskId: this.props.videoData.isTask,
        progress: 100,
        taskStatus: 2,
        report: [],
      };
      const { data: res } = await markTaskAsCompletedReq(req);
      if (res.success) {
        notifySuccess(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.message);
        this.props.dispatch(authLogout());
      }
    }
  };

  handleModalToggle = () => {
    if (
      this.props.videoplayerModal &&
      !_.isEmpty(this.props.videoData.contentId)
    ) {
      // it came for close
      // check if video played upto 80%
      const { duration, playedSeconds } = this.state;
      const playedPercentage = Math.ceil((playedSeconds / duration) * 100);
      let _toggle = false;
      if (playedPercentage > 80) {
        // dispatch method to open journaling question popup
        if (_.isEmpty(this.props.journalingQuestionData.learnedFromVideo)) {
          _toggle = true;
        } else if (_.isEmpty(this.props.journalingQuestionData.goodOutcome)) {
          _toggle = true;
        } else if (
          _.isEmpty(this.props.journalingQuestionData.stepsForAchievement)
        ) {
          _toggle = true;
        }
        if (_toggle) {
          this.props.dispatch(toggleJournalingQuestionModal());
        }
      }
      if (!_toggle) {
        this.props.dispatch(togglePlayerModal());
      }
    } else {
      this.props.dispatch(togglePlayerModal());
    }
  };

  handleEnded = () => {
    // console.log("onEnded");
    this.setState({ playing: this.state.loop });
  };

  handleDuration = (duration) => {
    // console.log("onDuration", duration);
    this.setState({ duration });
  };

  handleClickFullscreen = () => {
    // screenfull.request(findDOMNode(this.player));
  };

  renderLoadButton = (url, label) => {
    return <button onClick={() => this.load(url)}>{label}</button>;
  };

  performAction = async (contentId, actionName) => {
    try {
      let req = {
        contentId,
        actionName,
      };
      const { data: res } = await doAction(req);
      if (res.success == true) {
        this.props.dispatch(
          setJournalingQuestionData(res.response.contentAction)
        );
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.message);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
  };

  ref = (player) => {
    this.player = player;
  };

  render() {
    const {
      url,
      playing,
      speedlist,
      controls,
      light,
      volume,
      muted,
      loop,
      played,
      loaded,
      duration,
      playbackRate,
      pip,
    } = this.state;

    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.videoplayerModal}
          toggle={this.handleModalToggle}
          backdrop="static"
          className="videoplayer-modal"
        >
          <ModalHeader toggle={this.handleModalToggle} className="pb-0">
            <Row>
              <Col xl={12}>
                <h2 className="video-title">{this.props.videoData.title}</h2>
                {!_.isEmpty(this.props.videoData.contentId) && (
                  <React.Fragment>
                    <span className="video-coach-name">
                      by {this.props.videoData.coach}
                    </span>
                    <span className="mb-0 video-category-name">
                      Category: <strong>{this.props.videoData.category}</strong>
                    </span>
                  </React.Fragment>
                )}
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody className="p-0">
            <div className="videoContainer">
              <div className="overlay">
                <div
                  className="bigPlayButton"
                  onClick={this.handlePlayPause}
                ></div>
              </div>
              <div className="video">
                <ReactPlayer
                  ref={this.ref}
                  className="react-player"
                  forceplayer={FilePlayer}
                  // width="100%"
                  height="100%"
                  url={this.props.videoData.url}
                  pip={pip}
                  playing={playing}
                  controls={controls}
                  light={light}
                  loop={loop}
                  playbackRate={playbackRate}
                  stopOnUnmount={false}
                  volume={volume}
                  muted={muted}
                  onReady={() => console.log("onReady")}
                  onStart={() => console.log("onStart")}
                  onPlay={this.handlePlay}
                  onEnablePIP={this.handleEnablePIP}
                  onDisablePIP={this.handleDisablePIP}
                  onPause={this.handlePause}
                  onBuffer={() => console.log("onBuffer")}
                  onSeek={(e) => console.log("onSeek", e)}
                  onEnded={this.handleEnded}
                  onError={(e) => console.log("onError", e)}
                  onProgress={this.handleProgress}
                  onDuration={this.handleDuration}
                />
              </div>

              <div className="controls">
                <button
                  className="controlButton"
                  onClick={this.handlePlayPause}
                >
                  <i className={`mdi mdi-${playing ? "pause" : "play"}`}></i>
                </button>
                {/* <button
                          className="controlButton"
                          title="Previous video"
                        >
                          <span className="material-icons">skip_previous</span>
                        </button>
                        <div className="controlButton playButton">
                          <span className="material-icons">play_arrow</span>
                        </div>
                        <button className="controlButton" title="Next video">
                          <span className="material-icons">skip_next</span>
                        </button>
                        <button
                          className="controlButton"
                          title="Fast forward 5 seconds"
                        >
                          <span className="material-icons">forward_5</span>
                        </button> */}

                {/* <tr>
                          <th>remaining</th>
                          <td>
                            <Duration seconds={duration * (1 - played)} />
                          </td>
                        </tr> */}
                <div className="progressContainer">
                  <div className="timer intialTime">
                    <Duration seconds={duration * played} />
                  </div>

                  {/* <div className="progress"></div> */}
                  <input
                    type="range"
                    className="slider"
                    min={0}
                    max={0.999999}
                    step="any"
                    value={played}
                    onMouseDown={this.handleSeekMouseDown}
                    onChange={this.handleSeekChange}
                    onMouseUp={this.handleSeekMouseUp}
                  />
                  <div className="time">
                    <Duration seconds={duration * played} />
                  </div>
                  <div className="timer fullTime">
                    <Duration seconds={duration} />
                  </div>
                </div>

                <div className="volume">
                  <div
                    className="controlButton"
                    onClick={this.handleToggleMuted}
                  >
                    <span
                      className={`mdi mdi-volume-${muted ? "mute" : "high"}`}
                    ></span>
                  </div>

                  <div className="">
                    <input
                      type="range"
                      className="slider"
                      min={0}
                      max={1}
                      step="any"
                      value={volume}
                      onChange={this.handleVolumeChange}
                    />
                  </div>
                </div>

                <button
                  className="controlButton speedButton"
                  style={{ float: "right" }}
                  title="Video speed"
                >
                  <span
                    className="mdi mdi-play-speed"
                    onClick={this.handleToggleSpeedList}
                  ></span>
                </button>
                <ul className={`speedList ${speedlist ? "" : "d-none"}`}>
                  <li
                    onClick={() => this.handleSetPlaybackRate(2)}
                    className={`spdx50 ${playbackRate == 2 ? "selected" : ""}`}
                  >
                    2
                  </li>
                  <li
                    onClick={() => this.handleSetPlaybackRate(1.5)}
                    className={`spdx50 ${
                      playbackRate == 1.5 ? "selected" : ""
                    }`}
                  >
                    1.5
                  </li>
                  <li
                    onClick={() => this.handleSetPlaybackRate(1.25)}
                    className={`spdx25 ${
                      playbackRate == 1.25 ? "selected" : ""
                    }`}
                  >
                    1.25
                  </li>
                  <li
                    onClick={() => this.handleSetPlaybackRate(1)}
                    className={`spdx1 ${playbackRate == 1 ? "selected" : ""}`}
                  >
                    Normal
                  </li>
                  <li
                    onClick={() => this.handleSetPlaybackRate(0.5)}
                    className={`spdx050 ${
                      playbackRate == 0.5 ? "selected" : ""
                    }`}
                  >
                    0.5
                  </li>
                </ul>
                {ReactPlayer.canEnablePIP(this.props.videoData.url) && (
                  <button
                    className="controlButton"
                    onClick={this.handleTogglePIP}
                  >
                    <span
                      className={`mdi mdi-picture-in-picture-bottom-right${
                        pip ? "-outline" : ""
                      }`}
                    ></span>
                  </button>
                )}
                <button className="controlButton">
                  <span className="fa fa-expand"></span>
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAdminAuthenticated: state.auth.isAdminAuthenticated,
    isAuthenticated: state.auth.isAuthenticated,
    videoplayerModal: state.generalActions.videoplayerModal,
    videoData: state.generalActions.videoData,
    user: state.auth.user,
    journalingQuestionData: state.generalActions.journalingQuestionData,
  };
};

export default connect(mapStateToProps)(VideoPlayer);
