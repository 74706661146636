// import lib
import { lazy } from "react";

const adminRoutes = [
  {
    path: "/admin/login",
    exact: true,
    routefor: "admin",
    layout: "",
    component: lazy(() =>
      import(
        /* webpackChunkName: "ad-login" */ "./../components/admin/auth/login"
      )
    ),
  },
  {
    path: "/admin/forgot-password",
    exact: true,
    routefor: "admin",
    layout: "",
    component: lazy(() =>
      import(
        /* webpackChunkName: "ad-forgot-password" */ "./../components/admin/auth/forgotpassword"
      )
    ),
  },
  {
    path: "/admin/password/reset/:token",
    exact: true,
    routefor: "admin",
    component: lazy(() =>
      import(
        /* webpackChunkName: "ad-reset-password" */ "./../components/admin/auth/resetpassword"
      )
    ),
  },
  {
    path: "/admin/auth-check",
    exact: true,
    // auth: true,
    routefor: "admin",
    component: lazy(() =>
      import(
        /* webpackChunkName: "ad-auth-check" */ "./../components/admin/auth/authcheck"
      )
    ),
  },
  {
    path: "/admin/dashboard",
    exact: true,
    auth: true,
    routefor: "admin",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "admin-dashboard" */ "./../components/admin/dashboard"
      )
    ),
  },
  {
    path: "/admin/enterprises",
    exact: true,
    auth: true,
    routefor: "admin",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "admin-enterprise" */ "./../components/admin/enterprise/list"
      )
    ),
  },
  {
    path: "/admin/enterprise/detail/:id",
    exact: true,
    auth: true,
    routefor: "admin",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "admin-enterprise-details" */ "./../components/admin/enterprise/details"
      )
    ),
  },
  {
    path: "/admin/tasks-library",
    exact: true,
    auth: true,
    routefor: "admin",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "admin-taskLibrary" */ "./../components/admin/taskLibrary/list"
      )
    ),
  },
  {
    path: "/admin/create-task",
    exact: true,
    auth: true,
    routefor: "admin",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "admin-createtask" */ "./../components/admin/taskLibrary/create"
      )
    ),
  },
  {
    path: "/admin/tasks-library/edit/:id",
    exact: true,
    auth: true,
    routefor: "admin",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "admin-edittask" */ "./../components/admin/taskLibrary/edit"
      )
    ),
  },
  {
    path: "/admin/tasks-library/view/:id",
    exact: true,
    auth: true,
    routefor: "admin",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "admin-viewtask" */ "./../components/admin/taskLibrary/view"
      )
    ),
  },
  {
    path: "/admin/content-library",
    exact: true,
    auth: true,
    routefor: "admin",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "admin-contentLibrary" */ "./../components/admin/contentLibrary/list"
      )
    ),
  },
  {
    path: "/admin/content-library/edit/:id",
    exact: true,
    auth: true,
    routefor: "admin",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "admin-contentLibrary-Edit" */ "./../components/admin/contentLibrary/edit"
      )
    ),
  },
  {
    path: "/admin/create-content",
    exact: true,
    auth: true,
    routefor: "admin",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "admin-createcontent" */ "./../components/admin/contentLibrary/create"
      )
    ),
  },
  {
    path: "/admin/bulk-assign-unassign-content",
    exact: true,
    auth: true,
    routefor: "admin",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "admin-bulkAssignUnassignContent" */ "./../components/admin/contentLibrary/bulkAssignUnassignContent"
      )
    ),
  },
  {
    path: "/admin/profile",
    exact: true,
    auth: true,
    routefor: "admin",
    layout: "fullpage",
    component: lazy(() =>
      import(
        /* webpackChunkName: "admin-profile" */ "../components/admin/auth/profile"
      )
    ),
  },
  {
    path: "/admin/vocabulary",
    exact: true,
    auth: true,
    routefor: "admin",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "admin-vocabulary" */ "./../components/admin/vocabulary/list"
      )
    ),
  },
  {
    path: "/admin/reports",
    exact: true,
    auth: true,
    routefor: "admin",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "admin-reports" */ "./../components/admin/reports"
      )
    ),
  },
];

export default adminRoutes;
