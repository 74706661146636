import React, { Component } from "react";
import { connect } from "react-redux";

import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");
    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/admin/dashboard" className="waves-effect">
                <i className="ri-dashboard-line"></i>
                {/* <span className="badge badge-pill badge-success float-right">
                  3
                </span> */}
                <span className="ml-1">Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/enterprises" className="waves-effect">
                <i className="ri-building-4-line"></i>
                <span className="ml-1">Enterprises</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/tasks-library" className="waves-effect">
                <i className="ri-file-edit-fill"></i>
                <span className="ml-1">Task Library</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/content-library" className="waves-effect">
                <i className="ri-todo-line"></i>
                <span className="ml-1">Content Library</span>
              </Link>
            </li>
            {/* <li>
              <Link
                to="/content-library"
                onClick={(e) => e.preventDefault()}
                className="waves-effect"
              >
                <i className="ri-price-tag-3-fill"></i>
                <span className="ml-1">Plan</span>
              </Link>
            </li> */}
            <li>
              <Link
                to="/admin/reports"
                className="waves-effect"
              >
                <i className="ri-price-tag-3-fill"></i>
                <span className="ml-1">Reports</span>
              </Link>
            </li>
            {/* <li>
              <Link
                to="/content-library"
                onClick={(e) => e.preventDefault()}
                className="waves-effect"
              >
                <i className="ri-price-tag-3-fill"></i>
                <span className="ml-1">User Roles</span>
              </Link>
            </li> */}
            <li>
              <Link to="/admin/vocabulary" className="waves-effect">
                <i className="ri-price-tag-3-fill"></i>
                <span className="ml-1">Vocabulary List</span>
              </Link>
            </li>

            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-share-line"></i>
                <span className="ml-1">Multi Level</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <Link to="/#">Level 1.1</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    Level 1.2
                  </Link>
                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <Link to="/#">Level 2.1</Link>
                    </li>
                    <li>
                      <Link to="/#">Level 2.2</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { isAuthenticated: state.auth.isAuthenticated };
};

export default withRouter(connect(mapStatetoProps)(SidebarContent));
